import React, { Suspense, useMemo } from 'react';
import { Flex } from 'glints-aries/es';
import { Spinner } from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { uniqueId } from 'lodash-es';
import {
  createHtmlPortalNode,
  InPortal,
  OutPortal,
} from 'react-reverse-portal';
import { MessageList, useChannelStateContext } from 'stream-chat-react';
import styled from 'styled-components';

import RenderBasedOnApplicationAccessLevel from '../../../components/FullProfileAccess/RenderBasedOnApplicationAccessLevel';
import VipAccessNeededToViewDetails from '../../../components/FullProfileAccess/VipAccessNeededToViewDetails';
import {
  ApplicationChatEphemeralActionType,
  useMessagingChannelContext,
} from '../constants';
import { useGetApplicationWithChannelAndMetadata } from '../graphql';
import { customGroupStyles } from '../helper';
import { useTrackMessageSentEvent } from '../hooks';
import MessagingChannelHeader from './ChannelHeader';
import MessagingInput from './MessageInput';
import MessageVariantController from './MessageVariantController';

const LoadingChannels = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    style={{
      height: '400px',
    }}
  >
    <Spinner size="large" fill={Blue.S99} />
  </Flex>
);

const LimitAccessStyles = {
  BackdropContainer: styled.div`
    background-color: rgba(45, 45, 45, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  `,
  AccessLimitContainer: styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${Neutral.B100};
    z-index: 100;
  `,
  AccessContainer: styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
  `,
};

const MessagingChannelComponent = () => {
  useTrackMessageSentEvent();
  const { messages = [] } = useChannelStateContext();
  const { showInputPhoneNumber, ephemeralMessages } =
    useMessagingChannelContext();
  const { data, loading } = useGetApplicationWithChannelAndMetadata();
  const channelHeaderPortalNode = useMemo(() => createHtmlPortalNode(), []);
  const messageListPortalNode = useMemo(
    () =>
      createHtmlPortalNode({
        attributes: {
          class: 'chat-list-portal-wrapper',
        },
      }),
    []
  );
  const messageInputPortalNode = useMemo(() => createHtmlPortalNode(), []);
  const filteredMessages = useMemo(
    () => messages.filter(message => !message?.glints_hide_for_employer),
    [messages]
  );

  const _messages = showInputPhoneNumber
    ? [
        ...filteredMessages,
        {
          id: uniqueId(ApplicationChatEphemeralActionType.INPUT_PHONE),
          glints_application_chat: {
            action_type: ApplicationChatEphemeralActionType.INPUT_PHONE,
          },
        },
      ]
    : filteredMessages;
  const applicationData = data?.getApplicationById;

  return (
    <>
      <InPortal node={channelHeaderPortalNode}>
        <MessagingChannelHeader />
      </InPortal>
      <InPortal node={messageListPortalNode}>
        <MessageList
          groupStyles={customGroupStyles}
          messageActions={['edit', 'delete']}
          messages={_messages.concat(ephemeralMessages)}
          Message={MessageVariantController}
          returnAllReadData={true}
        />
      </InPortal>
      <InPortal node={messageInputPortalNode}>
        <MessagingInput />
      </InPortal>

      <Choose>
        <When condition={loading || !applicationData}>
          <LoadingChannels />
        </When>
        <Otherwise>
          <OutPortal node={channelHeaderPortalNode} />
          {applicationData && (
            <RenderBasedOnApplicationAccessLevel
              jobId={applicationData.job.id}
              applicationDetails={{
                id: applicationData.id,
                status: applicationData.status,
                employerMetadata: applicationData.accessLevel
                  ? { accessLevel: applicationData.accessLevel }
                  : undefined,
              }}
              renderVipAccessContent={({ initialBalance }) => (
                <LimitAccessStyles.AccessContainer>
                  <LimitAccessStyles.BackdropContainer
                    onClick={e => e.stopPropagation()}
                  >
                    <LimitAccessStyles.AccessLimitContainer>
                      <VipAccessNeededToViewDetails.ChatAccessLimit
                        initialBalance={initialBalance}
                      />
                    </LimitAccessStyles.AccessLimitContainer>
                  </LimitAccessStyles.BackdropContainer>
                  <OutPortal node={messageListPortalNode} />
                </LimitAccessStyles.AccessContainer>
              )}
            >
              <OutPortal node={messageListPortalNode} />
              <OutPortal node={messageInputPortalNode} />
            </RenderBasedOnApplicationAccessLevel>
          )}
        </Otherwise>
      </Choose>
    </>
  );
};

export default function MessagingChannel() {
  return (
    <Suspense fallback={<LoadingChannels />}>
      <MessagingChannelComponent />
    </Suspense>
  );
}
