import { useCallback } from 'react';
import { convertFromRaw } from 'draft-js';
import { isNull, omit } from 'lodash-es';
import { useSelector } from 'react-redux';

import {
  hierarchicalLocationSupportedCountryCodes,
  JOB_TYPE,
} from '../../../common/constants';
import {
  countryCodeIsIndonesia,
  countryCodeIsVietnam,
} from '../../../common/helpers/countryCodeHelper';
import { useGetSessionCompany } from '../../../common/hooks/useGetSessionCompany';
import useIsMustHaveProfilePictureEnabled from '../../../common/hooks/useIsMustHaveProfilePictureEnabled';
import useIsVIPMembershipEnabled from '../../../common/hooks/useIsVIPMembershipEnabled';
import { Job } from '../../../common/interfaces/entities';
import { JobResponse } from '../../../common/interfaces/responses';
import { useGlintsVIPMembershipInfo } from '../../../components/GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import { getIsFeatureEnabled } from '../../../selectors/features';
import { UnleashFeatureNames } from '../../Unleash/featureNames';
import {
  BonusFrequency,
  DEFAULT_JOB_STATUS,
  DEFAULT_NUMBER_OF_HIRES,
  GenderPreferenceForJob,
  getDefaultMinSalaryAmount,
  INDONESIA_COUNTRY,
  JobDraftFormState,
  JobDraftPostingPayload,
  JobFormState,
  JobPostingPayload,
  JobStatus,
  SalaryBasic,
  SalaryBonus,
  SalaryType,
  Seniority,
} from '../common/constants';
import {
  convertScreeningQuestionFormStateToPayload,
  convertScreeningQuestionResponseToFormState,
} from './screeningQuestionsHelpers';
import useGetVIPJobFieldsDefaultValue from './useGetVIPJobFieldsDefaultValue';

const yearsOfExperienceToSeniority = (
  min: number,
  max: number
): Seniority | undefined => {
  if (min === 0 && max === 1) return Seniority.LESS_THAN_A_YEAR;
  if (min === 1 && max === 3) return Seniority.ONE_TO_THREE;
  if (min === 3 && max === 5) return Seniority.THREE_TO_FIVE;
  if (min === 5 && max === 10) return Seniority.FIVE_TO_TEN;
  if (min === 10 && max === 50) return Seniority.TEN_PLUS;
  if (min === 0 && max === 50) return Seniority.NO_PREFERENCE;
  return undefined;
};

export const seniorityToYearsOfExperience = (
  seniority: Seniority
): { minYearsOfExperience: number; maxYearsOfExperience: number } =>
  ({
    [Seniority.LESS_THAN_A_YEAR]: {
      minYearsOfExperience: 0,
      maxYearsOfExperience: 1,
    },
    [Seniority.ONE_TO_THREE]: {
      minYearsOfExperience: 1,
      maxYearsOfExperience: 3,
    },
    [Seniority.THREE_TO_FIVE]: {
      minYearsOfExperience: 3,
      maxYearsOfExperience: 5,
    },
    [Seniority.FIVE_TO_TEN]: {
      minYearsOfExperience: 5,
      maxYearsOfExperience: 10,
    },
    [Seniority.TEN_PLUS]: {
      minYearsOfExperience: 10,
      maxYearsOfExperience: 50,
    },
    [Seniority.NO_PREFERENCE]: {
      minYearsOfExperience: 0,
      maxYearsOfExperience: 50,
    },
  })[seniority];

const useConvertJobData = () => {
  const { sessionCompany } = useGetSessionCompany();
  const CompanyId = sessionCompany?.id || '';
  const isMustHaveLocationFieldsEnabled = useSelector(state =>
    getIsFeatureEnabled(
      state,
      UnleashFeatureNames.empMustHaveJobLocationAndEducation
    )
  );

  const isVIPMembershipEnabled = useIsVIPMembershipEnabled({
    skipCompanyCountryCheck: true,
  });

  const isMustHaveProfilePictureEnabled = useIsMustHaveProfilePictureEnabled();

  const { vipMembershipInfo } = useGlintsVIPMembershipInfo();
  const { vipJobFieldsDefaultValue } = useGetVIPJobFieldsDefaultValue();

  const convertJobFormStateToPayload = useCallback(
    (
      jobFormState: JobFormState | JobDraftFormState
    ): JobPostingPayload | JobDraftPostingPayload => {
      const candidateMustHaveFields = (function () {
        if (
          jobFormState.isEdit ||
          jobFormState.CountryCode !== INDONESIA_COUNTRY.code ||
          !isMustHaveLocationFieldsEnabled
        ) {
          return {};
        }

        const mustHaveFields = {
          isLocationRequiredForCandidate: Boolean(
            jobFormState.isLocationRequiredForCandidate
          ),
          isEducationRequiredForCandidate: Boolean(
            jobFormState.isEducationRequiredForCandidate
          ),
        };

        return {
          ...mustHaveFields,
          ...(isVIPMembershipEnabled && {
            isResumeRequiredForCandidate: Boolean(
              jobFormState.isResumeRequiredForCandidate
            ),
            ...(isMustHaveProfilePictureEnabled && {
              vipCandidateInformation: {
                required: {
                  profilePic: jobFormState.isProfilePicRequiredForCandidate,
                },
              },
            }),
          }),
        };
      })();

      const shouldShowSalary = (function () {
        const isCompanyCountryAndJobCountryVN = [
          sessionCompany?.CountryCode || '',
          jobFormState.CountryCode || '',
        ].every(countryCodeIsVietnam);

        const isJobPostCountryIsID =
          countryCodeIsIndonesia(jobFormState.CountryCode || '') &&
          !jobFormState.isEdit;

        if (
          !isVIPMembershipEnabled ||
          isJobPostCountryIsID ||
          isCompanyCountryAndJobCountryVN
        ) {
          return {
            shouldShowSalary: jobFormState.shouldShowSalary,
          };
        }

        return {};
      })();

      const salaries: [SalaryBasic?, SalaryBonus?] = [];
      if (jobFormState?.minSalaryAmount && jobFormState?.CurrencyCode) {
        const newSalary = {
          salaryType: SalaryType.BASIC,
          salaryMode: BonusFrequency.MONTH,
          minAmount: jobFormState.minSalaryAmount,
          CurrencyCode: jobFormState.CurrencyCode,
          maxAmount: jobFormState?.maxSalaryAmount || null,
        };
        salaries.push(newSalary as SalaryBasic);
      }

      if (
        jobFormState?.CurrencyCode &&
        jobFormState?.addBonus &&
        typeof jobFormState?.minBonusAmount !== 'undefined' &&
        jobFormState?.minBonusAmount !== null &&
        jobFormState?.maxBonusAmount &&
        jobFormState?.minBonusAmount < jobFormState?.maxBonusAmount
      ) {
        salaries.push({
          salaryType: SalaryType.BONUS,
          salaryMode: jobFormState?.salaryMode,
          minAmount: jobFormState?.minBonusAmount,
          maxAmount: jobFormState?.maxBonusAmount,
          CurrencyCode: jobFormState?.CurrencyCode,
        } as SalaryBonus);
      }

      return {
        status: DEFAULT_JOB_STATUS as JobStatus,
        CompanyId,
        numberOfHires: DEFAULT_NUMBER_OF_HIRES,
        receiverIds: jobFormState.receiverIds,
        ...(jobFormState.seniority &&
          seniorityToYearsOfExperience(jobFormState.seniority)),
        skills: jobFormState?.skills?.map(skill => ({
          id: skill.id,
          mustHave: true,
        })),
        title: jobFormState.title,
        type: jobFormState.type,
        HierarchicalJobCategoryId: jobFormState.HierarchicalJobCategoryId,
        JobCategoryId: jobFormState.JobCategoryId,
        workArrangementOption: jobFormState.workArrangementOption,
        ...(jobFormState.CountryCode &&
        hierarchicalLocationSupportedCountryCodes.includes(
          jobFormState.CountryCode
        )
          ? { DistrictId: jobFormState.districtLocation?.id }
          : {
              CityId: jobFormState.CityId,
              DistrictId: null,
            }),
        CountryCode: jobFormState.CountryCode,
        acceptsForeignApplications: jobFormState.acceptsForeignApplications,
        descriptionRaw: jobFormState.descriptionRaw,
        salaries: salaries as [SalaryBasic, SalaryBonus?],
        interviewProcessRaw: jobFormState.interviewProcess
          ? jobFormState.interviewProcessRaw
          : null,
        shouldPublishOnLinkedin: jobFormState.shouldPublishOnLinkedin,
        benefits: jobFormState.benefits?.map(benefit => ({
          benefit: benefit.benefit,
          description: benefit.description,
        })),
        shouldShowBenefits: true,
        educationLevel: jobFormState.educationLevel,
        CreatorId: jobFormState.CreatorId,
        minAge: jobFormState.minAge,
        maxAge: jobFormState.maxAge,
        gender:
          jobFormState.gender === GenderPreferenceForJob.NO_PREFERENCE
            ? null
            : jobFormState.gender,
        jobDraftId: jobFormState.jobDraftId,
        ...candidateMustHaveFields,
        ...shouldShowSalary,
        ...convertScreeningQuestionFormStateToPayload({
          screeningQuestions: jobFormState.screeningQuestions,
          isEdit: Boolean(jobFormState.isEdit),
          isVIPActive: vipMembershipInfo.isActive,
        }),
      };
    },
    [
      CompanyId,
      isMustHaveLocationFieldsEnabled,
      isVIPMembershipEnabled,
      isMustHaveProfilePictureEnabled,
      sessionCompany?.CountryCode,
      vipMembershipInfo.isActive,
    ]
  );

  const getSalaryStateFromResponse = useCallback((response: JobResponse) => {
    const jobSalary = (response as JobResponse).data.salaries;

    const basicSalary = jobSalary
      ? (jobSalary.find(salary => salary.salaryType === 'BASIC') as SalaryBasic)
      : null;

    const bonusSalary = jobSalary
      ? (jobSalary.find(salary => salary.salaryType === 'BONUS') as SalaryBonus)
      : null;

    return {
      addBonus: Boolean(bonusSalary),
      salaryMode: bonusSalary?.salaryMode || BonusFrequency.MONTH,
      minSalaryAmount:
        basicSalary?.minAmount ||
        getDefaultMinSalaryAmount(basicSalary?.CurrencyCode),
      maxSalaryAmount: basicSalary?.maxAmount || null,
      minBonusAmount: bonusSalary?.minAmount || 0,
      maxBonusAmount: bonusSalary?.maxAmount || 0,
      shouldShowSalary: response.data.shouldShowSalary,
      CurrencyCode: basicSalary?.CurrencyCode,
    };
  }, []);

  const getLocationStateFromResponse = useCallback((response: JobResponse) => {
    const jobLocation = response.data?.location;
    const districtId = response.data?.DistrictId;

    if (districtId) {
      return {
        districtLocation: omit(jobLocation, 'parents'),
        cityLocation: jobLocation?.parents?.[0],
        provinceLocation: jobLocation?.parents?.[1],
        originalDistrictId: districtId,
      };
    }

    if (jobLocation) {
      return {
        districtLocation: null,
        cityLocation: omit(jobLocation, 'parents'),
        provinceLocation: jobLocation?.parents?.[0],
        districtOptions: jobLocation.children,
      };
    }

    return {};
  }, []);

  const convertResponseToFormState = useCallback(
    (
      response: JobResponse,
      options?: { isEdit?: boolean }
    ): JobFormState | JobDraftFormState => {
      let jobData = response.data;

      const haveUnfilteredSkills = jobData.unfilteredSkills
        ? jobData.skills.length !== jobData.unfilteredSkills.length
        : false;

      const skillsData = !haveUnfilteredSkills
        ? jobData.skills?.map(skill => skill.skill)
        : [];

      jobData = {
        ...jobData,
        descriptionRaw: (jobData as Job).descriptionJsonString
          ? JSON.parse((jobData as Job).descriptionJsonString || '')
          : null,
        interviewProcessRaw: (jobData as Job).interviewProcessJsonString
          ? JSON.parse((jobData as Job).interviewProcessJsonString || '')
          : null,
      };

      const isCompanyCountryAndJobCountryVN = [
        sessionCompany?.CountryCode || '',
        jobData.CountryCode || '',
      ].every(countryCodeIsVietnam);

      const shouldResetVIPFieldsValues =
        isVIPMembershipEnabled &&
        !vipMembershipInfo.isActive &&
        !isCompanyCountryAndJobCountryVN;

      return {
        title: jobData.title || '',
        type: Object.values(JOB_TYPE).includes(jobData.type)
          ? jobData.type
          : '',
        HierarchicalJobCategoryId: jobData.HierarchicalJobCategoryId,
        originalHierarchicalJobCategoryId: jobData.HierarchicalJobCategoryId,
        HierarchicalJobCategoryName: jobData.HierarchicalJobCategoryId
          ? (jobData as Job).hierarchicalJobCategory?.leaves?.[0]?.name
          : null,
        categoryName: jobData.HierarchicalJobCategoryId
          ? (jobData as Job).hierarchicalJobCategory?.leaves?.[0]?.name
          : null,
        ...getLocationStateFromResponse(response),
        workArrangementOption: jobData.workArrangementOption,
        CityId: jobData.CityId,
        CountryCode: jobData.CountryCode,
        CreatorId: jobData.CreatorId,
        originalCreatorId: jobData.CreatorId,
        countryName: jobData.country?.name,
        isWhitelistedCountry: jobData.country
          ? jobData.country.isWhitelisted
          : true,
        acceptsForeignApplications: jobData.acceptsForeignApplications,
        description: jobData?.descriptionRaw
          ? convertFromRaw(jobData.descriptionRaw)
          : undefined,
        descriptionRaw: jobData.descriptionRaw,
        ...(!isNull(jobData?.minYearsOfExperience) &&
          !isNull(jobData?.maxYearsOfExperience) && {
            seniority: yearsOfExperienceToSeniority(
              jobData.minYearsOfExperience,
              jobData.maxYearsOfExperience
            ),
          }),
        skills: skillsData ?? [],
        shouldPublishOnLinkedin: jobData.shouldPublishOnLinkedin,
        ...getSalaryStateFromResponse(response),
        benefits: jobData.benefits,
        receiverIds: Array.from(new Set(jobData.receiverIds)),
        interviewProcess: jobData.interviewProcessRaw
          ? convertFromRaw(jobData.interviewProcessRaw)
          : undefined,
        interviewProcessRaw: jobData.interviewProcessRaw,
        educationLevel: jobData.educationLevel,
        noAgeLimit: !jobData.minAge && !jobData.maxAge,
        minAge: jobData.minAge,
        maxAge: jobData.maxAge,
        gender: (jobData.gender ||
          GenderPreferenceForJob.NO_PREFERENCE) as GenderPreferenceForJob,
        unfilteredSkills: jobData.unfilteredSkills
          ? jobData.unfilteredSkills.map(skill => skill.skill)
          : [],
        haveUnfilteredSkills,
        isLocationRequiredForCandidate: Boolean(
          jobData.isLocationRequiredForCandidate
        ),
        isEducationRequiredForCandidate: Boolean(
          jobData.isEducationRequiredForCandidate
        ),
        isResumeRequiredForCandidate: Boolean(
          jobData.isResumeRequiredForCandidate
        ),
        ...(shouldResetVIPFieldsValues && vipJobFieldsDefaultValue),
        screeningQuestions: convertScreeningQuestionResponseToFormState({
          screeningQuestions: jobData.screeningQuestions,
          isVIPActive: vipMembershipInfo.isActive,
          isEdit: Boolean(options?.isEdit),
        }),
        screeningQuestionsEnabled: jobData.screeningQuestionsEnabled,
        isProfilePicRequiredForCandidate: Boolean(
          jobData.vipCandidateInformation?.required?.profilePic
        ),
      };
    },
    [
      getLocationStateFromResponse,
      getSalaryStateFromResponse,
      isVIPMembershipEnabled,
      sessionCompany?.CountryCode,
      vipJobFieldsDefaultValue,
      vipMembershipInfo.isActive,
    ]
  );

  const convertResponseToPayload = useCallback(
    (response: JobResponse): JobPostingPayload | JobDraftPostingPayload =>
      convertJobFormStateToPayload(convertResponseToFormState(response)),
    [convertJobFormStateToPayload, convertResponseToFormState]
  );

  return {
    convertJobFormStateToPayload,
    convertResponseToFormState,
    convertResponseToPayload,
  };
};

export default useConvertJobData;
