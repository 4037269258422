import {
  gql,
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useChannelStateContext } from 'stream-chat-react';

import {
  ApplicationStatus,
  CANDIDATE_STATUS,
  EDUCATION_LEVEL,
  JOB_TYPE,
  JobStatus,
} from '../../../common/constants';
import {
  ApplicantWhatsAppDetails,
  ApplicationAccessLevel,
  Education,
  WorkExperience,
} from '../../../common/interfaces/entities';
import {
  ApplicationChatContactsExchangeRequestStatus,
  ApplicationChatCVExchangeRequestStatus,
} from '../constants';
import { getChannelApplicationMetadata } from '../helper';
import { useEmpRespondSideEffect } from '../hooks';

// createShareContactsRequest
const createShareContactsRequest = gql`
  mutation createShareContactsRequest($channelId: UUID!) {
    createShareContactsRequest(channelId: $channelId)
  }
`;

type CreateShareContactsRequestVariables = {
  channelId: string;
};

type CreateShareContactsRequestData = {
  createShareContactsRequest: string;
};

export const useCreateShareContactsRequest = (
  options?: MutationHookOptions<
    CreateShareContactsRequestData,
    CreateShareContactsRequestVariables
  >
) => {
  const empRespondSideEffect = useEmpRespondSideEffect();

  return useMutation<
    CreateShareContactsRequestData,
    CreateShareContactsRequestVariables
  >(createShareContactsRequest, {
    ...options,
    onCompleted: async data => {
      await options?.onCompleted?.(data);
      await empRespondSideEffect();
    },
  });
};

// updateShareContactsRequest
const updateShareContactsRequest = gql`
  mutation updateShareContactsRequest(
    $messageId: UUID!
    $input: UpdateShareContactsRequestInput!
  ) {
    updateShareContactsRequest(messageId: $messageId, input: $input)
  }
`;

type UpdateShareContactsRequestVariables = {
  messageId: string;
  input: Partial<{
    status: ApplicationChatContactsExchangeRequestStatus;
    hidden: boolean;
    telemetryEventProps: Record<string, unknown>;
  }>;
};

type UpdateShareContactsRequestData = {
  updateShareContactsRequest: boolean;
};

export const useUpdateShareContactsRequest = (
  options?: MutationHookOptions<
    UpdateShareContactsRequestData,
    UpdateShareContactsRequestVariables
  >
) => {
  const empRespondSideEffect = useEmpRespondSideEffect();

  return useMutation<
    UpdateShareContactsRequestData,
    UpdateShareContactsRequestVariables
  >(updateShareContactsRequest, {
    ...options,
    onCompleted: async (data, clientOptions) => {
      await options?.onCompleted?.(data);

      if (
        clientOptions?.variables?.input.status ===
        ApplicationChatContactsExchangeRequestStatus.REJECTED
      ) {
        return;
      }

      await empRespondSideEffect();
    },
  });
};

// createShareResumeRequest
const createShareResumeRequest = gql`
  mutation createShareResumeRequest($channelId: UUID!) {
    createShareResumeRequest(channelId: $channelId)
  }
`;

type CreateShareResumeRequestVariables = {
  channelId: string;
  fileName?: string;
};

type CreateShareResumeRequestData = {
  createShareResumeRequest: string;
};

export const useCreateShareResumeRequest = (
  options?: MutationHookOptions<
    CreateShareResumeRequestData,
    CreateShareResumeRequestVariables
  >
) => {
  const empRespondSideEffect = useEmpRespondSideEffect();

  return useMutation<
    CreateShareResumeRequestData,
    CreateShareResumeRequestVariables
  >(createShareResumeRequest, {
    ...options,
    onCompleted: async data => {
      await options?.onCompleted?.(data);
      await empRespondSideEffect();
    },
  });
};

// updateShareResumeRequest
const updateShareResumeRequest = gql`
  mutation updateShareResumeRequest(
    $messageId: UUID!
    $input: UpdateShareResumeRequestInput!
  ) {
    updateShareResumeRequest(messageId: $messageId, input: $input)
  }
`;

type UpdateShareResumeRequestVariables = {
  messageId: string;
  input: Partial<{
    status: ApplicationChatCVExchangeRequestStatus;
    hidden: boolean;
    telemetryEventProps: Record<string, unknown>;
  }>;
};

type UpdateShareResumeRequestData = {
  updateShareResumeRequest: boolean;
};

export const useUpdateShareResumeRequest = (
  options?: MutationHookOptions<
    UpdateShareResumeRequestData,
    UpdateShareResumeRequestVariables
  >
) => {
  const empRespondSideEffect = useEmpRespondSideEffect();

  return useMutation<
    UpdateShareResumeRequestData,
    UpdateShareResumeRequestVariables
  >(updateShareResumeRequest, {
    ...options,
    onCompleted: async (data, clientOptions) => {
      await options?.onCompleted?.(data);

      if (
        clientOptions?.variables?.input.status ===
        ApplicationChatCVExchangeRequestStatus.REJECTED
      ) {
        return;
      }

      await empRespondSideEffect();
    },
  });
};

// getApplicationById
const getApplicationById = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      id
      status
      job {
        id
        title
        status
        type
        minYearsOfExperience
        maxYearsOfExperience
        CountryCode
        createdAt
        updatedAt
      }
      applicant {
        id
        firstName
        lastName
        ugcFullName
        candidateStatus
        workDurationInMonths
        careerStartDate
        lastSeen
        city {
          id
          name
        }
        country {
          id
          code
          name
        }
        location {
          id
          formattedName
        }
        experiences {
          id
          title
          type
          startDate
          endDate
          organization
          isPresent
          educationLevel
          study
        }
      }
      whatsAppDetails {
        whatsAppNumber
        isAvailable
      }
      accessLevel
    }
  }
`;

type GetApplicationByIdVariables = {
  id: string;
};

export type ApplicationData = {
  id: string;
  status: ApplicationStatus;
  job: {
    id: string;
    title: string;
    status: JobStatus;
    type: keyof typeof JOB_TYPE;
    minYearsOfExperience: number;
    maxYearsOfExperience: number;
    CountryCode: string;
    createdAt: string;
    updatedAt: string;
  };
  applicant: {
    id: string;
    firstName?: string;
    lastName?: string;
    ugcFullName?: string;
    lastSeen?: string;
    candidateStatus: keyof typeof CANDIDATE_STATUS;
    workDurationInMonths: number;
    careerStartDate?: Date;
    city?: {
      id: string;
      name: string;
    };
    country: {
      id: string;
      name: string;
      code: string;
    };
    location?: {
      id: string;
      formattedName: string;
    };
    experiences: {
      id: string;
      title?: string;
      type: Array<WorkExperience | Education>;
      startDate: string;
      endDate?: string;
      organization?: string;
      isPresent: boolean;
      study?: string;
      educationLevel?: EDUCATION_LEVEL;
    }[];
  };
  whatsAppDetails: ApplicantWhatsAppDetails;
  accessLevel?: ApplicationAccessLevel;
};

export type GetApplicationByIdData = {
  getApplicationById: ApplicationData;
};

export const useGetApplicationById = (
  options?: QueryHookOptions<
    GetApplicationByIdData,
    GetApplicationByIdVariables
  >
) =>
  useQuery<GetApplicationByIdData, GetApplicationByIdVariables>(
    getApplicationById,
    options
  );

// getJobNotificationReceivers
const getJobNotificationReceivers = gql`
  query getJobNotificationReceivers($jobId: UUID!) {
    getJobNotificationReceivers(jobId: $jobId) {
      id
      receiver {
        id
        firstName
        lastName
        email
        profilePic
      }
    }
  }
`;

type GetJobNotificationReceiversVariables = {
  jobId: string;
};

type JobNotificationReceiver = {
  id: string;
  receiver: {
    id: string;
    firstName: null | string;
    lastName: null | string;
    email: string;
    profilePic: null | string;
  };
};

export type GetJobNotificationReceiversData = {
  getJobNotificationReceivers: JobNotificationReceiver[];
};

export const useGetJobNotificationReceivers = (
  options?: QueryHookOptions<
    GetJobNotificationReceiversData,
    GetJobNotificationReceiversVariables
  >
) =>
  useQuery<
    GetJobNotificationReceiversData,
    GetJobNotificationReceiversVariables
  >(getJobNotificationReceivers, options);

// deleteJobNotificationReceiver
const deleteJobNotificationReceiverById = gql`
  mutation deleteJobNotificationReceiverById($id: UUID!) {
    deleteJobNotificationReceiverById(id: $id)
  }
`;

type DeleteJobNotificationReceiverByIdVariables = {
  id: string;
};

export const useDeleteJobNotificationReceiverById = (
  options?: MutationHookOptions<
    boolean,
    DeleteJobNotificationReceiverByIdVariables
  >
) =>
  useMutation<boolean, DeleteJobNotificationReceiverByIdVariables>(
    deleteJobNotificationReceiverById,
    options
  );

export const useGetApplicationWithChannelAndMetadata = () => {
  const { channel } = useChannelStateContext();
  const metadata = getChannelApplicationMetadata(channel);
  const applicationId = metadata?.application.id;

  const { loading, data } = useGetApplicationById({
    skip: !applicationId,
    variables: {
      id: applicationId ?? '',
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return { data, loading, metadata, channel };
};

// getResponseRateByUserId
const getResponseRateByUserId = gql`
  query getResponseRate($userId: String!) {
    getResponseRate(userId: $userId)
  }
`;

type GetResponseRateData = {
  getResponseRate: null | number;
};

type GetResponseRateVariables = {
  userId: string;
};

export const useGetResponseRateByUserId = (
  options?: QueryHookOptions<GetResponseRateData, GetResponseRateVariables>
) =>
  useQuery<GetResponseRateData, GetResponseRateVariables>(
    getResponseRateByUserId,
    options
  );

// getResponseTimeByUserId
const getResponseTimeByUserId = gql`
  query getResponseTime($userId: String!) {
    getResponseTime(userId: $userId)
  }
`;

type GetResponseTimeData = {
  getResponseTime: null | number;
};

type GetResponseTimeVariables = {
  userId: string;
};

export const useGetResponseTimeByUserId = (
  options?: QueryHookOptions<GetResponseTimeData, GetResponseTimeVariables>
) =>
  useQuery<GetResponseTimeData, GetResponseTimeVariables>(
    getResponseTimeByUserId,
    options
  );
